<template>
  <v-card>
    <v-card-text>
      <div class="d-flex align-center pb-5">
        <h2>Conseillers et bons d'achat</h2>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="me-3"
          @click="showConseillerDialog(null)"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ mdiPlus }}
          </v-icon>
          <span>Ajouter un conseiller</span>
        </v-btn>
      </div>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="ConseillersData.data"
      :options.sync="pagination"
      :server-items-length="+ConseillersData.total"
      :loading="loading"
      :expanded.sync="expanded"
      item-key="id"
      loading-text="Chargement des données..."
      no-data-text="Aucun résulat"
      show-expand
      single-expand
      :footer-props="{
        itemsPerPageText: 'Conseillers par page :',
        itemsPerPageOptions: [5,15,25,50,-1]
      }"
    >
      <template
        v-slot:expanded-item="{ headers, item }"
      >
        <td :colspan="headers.length">
          <div v-if="item.nom_referent">
            Référant :  {{ item.nom_referent }} - {{ item.telephone_referent }} - {{ item.email_referent }}
          </div>
          <div v-else>
            Aucun référant défini.
          </div>
        </td>
      </template>
      <template #[`item.nb_bons`]="{ item }">
        {{ item.nb_bons === null ? 'Aucun' : item.nb_bons }}
      </template>
      <template #[`item.nb_utilises`]="{ item }">
        {{ item.nb_utilises === null ? '/' : item.nb_utilises }}
      </template>
      <template #[`item.actions`]="{ item }">
        <v-btn
          icon
          title="Liste des adhérents ajoutés"
          @click="showListDialog(item)"
        >
          <v-icon>
            {{ mdiAccountDetailsOutline }}
          </v-icon>
        </v-btn>
        <v-btn
          icon
          @click="showConseillerDialog(item)"
        >
          <v-icon>
            {{ mdiPencil }}
          </v-icon>
        </v-btn>
        <v-btn
          icon
          @click="showDelete(item.id)"
        >
          <v-icon>
            {{ mdiDeleteOutline }}
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog
      v-model="showDeleteDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          Supprimer l'utilisateur ?
        </v-card-title>
        <v-card-text>Supprimer ?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            dark
            outlined
            :disabled="deleteLoading"
            @click="showDeleteDialog = false"
          >
            Annuler
          </v-btn>
          <v-btn
            color="primary"
            :loading="deleteLoading"
            dark
            @click="deleteConseiller()"
          >
            Confirmer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="isDialogOpen"
      max-width="650px"
    >
      <v-card class="user-edit-info pa-sm-10 pa-3">
        <v-card-title class="justify-center text-h5">
          {{ titleConseiller }}
        </v-card-title>
        <v-alert
          v-if="errorMessages"
          color="error"
          class="ml-5 mr-5 mb-5 align-center"
          dark
        >
          {{ errorMessages }}
        </v-alert>

        <v-card-text class="mt-5">
          <v-form
            ref="form"
            class="multi-col-validation"
            autocomplete="off"
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="conseillerFormData.cabinet"
                  outlined
                  dense
                  label="Cabinet"
                  :rules="[validators.required]"
                  :error-messages="errorField['conseiller.cabinet']"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="conseillerFormData.responsable"
                  outlined
                  dense
                  label="Responsable"
                  :rules="[validators.required]"
                  :error-messages="errorField['conseiller.responsable']"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="conseillerFormData.telephone"
                  outlined
                  dense
                  label="Téléphone"
                  :rules="[validators.required]"
                  :error-messages="errorField['conseiller.telephone']"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="conseillerFormData.email"
                  outlined
                  dense
                  label="E-mail"
                  :rules="[validators.required, validators.emailValidator]"
                  :error-messages="errorField['conseiller.email']"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="conseillerFormData.nom_referent"
                  outlined
                  dense
                  label="Nom référent"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="conseillerFormData.telephone_referent"
                  outlined
                  dense
                  label="Téléphone référent"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="conseillerFormData.email_referent"
                  outlined
                  dense
                  label="E-mail référent"
                  :rules="[validators.emailValidator]"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                class="d-flex justify-center mt-3"
              >
                <h3>Bons d'achat</h3>
              </v-col>
              <v-col
                cols="12"
                md="7"
                class="d-flex"
              >
                <v-text-field
                  v-model="conseillerFormData.code"
                  outlined
                  dense
                  label="Code"
                  :error-messages="errorField['bon.code']"
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-btn
                  class="ml-2"
                  color="secondary"
                  outlined
                  @click="codeGenerate"
                >
                  Générer
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                md="5"
              >
                <v-text-field
                  v-model="conseillerFormData.nb_bons"
                  type="number"
                  outlined
                  dense
                  label="Nombre de bons"
                  :error-messages="errorField['bon.nb_bons']"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                class="d-flex justify-center"
              >
                <v-switch
                  v-if="conseillerFormData.id === null"
                  v-model="notify"
                  label="Envoyer une notification au cabinet de conseil"
                ></v-switch>
              </v-col>
              <v-col
                cols="12"
                class="d-flex justify-center mt-2"
              >
                <v-btn
                  color="primary"
                  class="me-3"
                  type="submit"
                  :loading="loadingBtModal"
                  @click.prevent="onSubmit"
                >
                  Sauvegarder
                </v-btn>

                <v-btn
                  outlined
                  :disabled="loadingBtModal"
                  color="secondary"
                  @click.prevent="isDialogOpen = false"
                >
                  Annuler
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="isListOpen"
      scrollable
      max-width="750px"
    >
      <v-card class="user-edit-info pa-sm-10 pa-3">
        <v-card-title class="d-flex justify-space-between text-h5">
          Adhérents ajoutés par {{ currentConseiller.nom }}
          <v-btn
            outlined
            color="info"
            @click.prevent="printList"
          >
            <v-icon left>
              {{ mdiPrinterOutline }}
            </v-icon>
            Imprimer
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="totalAdherents">
          <v-data-table
            :headers="[
              { text: 'NOM', sortable: false, value: 'fullName' },
              { text: 'STATUT', sortable: false, value: 'statutAdherent', align: 'center' },
              { text: 'PRÉJUDICE', sortable: false, value: 'prejudice', align: 'end' },
            ]"
            :footer-props="{
              itemsPerPageText: 'Adhérents par page :',
              itemsPerPageOptions: [25,50,-1],
              itemsPerPage: 25
            }"
            :items="listAdherents"
            :loading="loadingList"
            loading-text="Chargement des adhérents..."
            class="elevation-1"
            :height="tableAdherentsHeight"
            fixed-header
          >
          </v-data-table>
          <!-- <v-list-item
              v-for="(adherent, i) in listAdherents"
              :key="i"
            >
              <v-list-item-content>
                <v-row>
                  <v-col><v-list-item-title v-text="adherent.fullName"></v-list-item-title></v-col>
                  <v-col><v-list-item-title v-text="adherent.statutAdherent"></v-list-item-title></v-col>
                  <v-col><v-list-item-title v-text="adherent.prejudice"></v-list-item-title></v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item> -->
        </v-card-text>
        <v-card-text v-else>
          Aucune adhésion ajoutée par {{ currentConseiller.nom }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="me-3"
            type="submit"
            :loading="loadingSendList"
            :disabled="!totalAdherents"
            @click.prevent="sendList(currentConseiller.id)"
          >
            <v-icon left>
              {{ mdiSendOutline }}
            </v-icon>
            Envoyer à {{ currentConseiller.responsable }}
          </v-btn>

          <v-btn
            outlined
            :disabled="loadingSendList"
            color="secondary"
            @click.prevent="isListOpen = false"
          >
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      color="success"
      :timeout="2000"
      outlined
      centered
      text
    >
      <div class="text-center">
        {{ snackbarText }}
      </div>
    </v-snackbar>
  </v-card>
</template>

<script>
import axios from '@axios'
import { emailValidator, required } from '@core/utils/validation'
import {
  mdiAccountDetailsOutline, mdiDeleteOutline, mdiEyeOffOutline, mdiEyeOutline, mdiPencil, mdiPlus, mdiPrinterOutline, mdiSendOutline,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import { currencyFormat } from '../../../@core/utils'

export default {
  setup() {
    const ConseillersData = ref([])
    const conseillerFormData = ref({})
    const expanded = ref([])
    const loading = ref(true)
    const tableAdherentsHeight = ref(0)
    const loadingList = ref(false)
    const loadingBtModal = ref(false)
    const loadingSendList = ref(false)
    const isDialogOpen = ref(false)
    const isListOpen = ref(false)
    const showDeleteDialog = ref(false)
    const titleConseiller = ref(null)
    const form = ref(null)
    const errorMessages = ref(null)
    const errorField = ref({})
    const deleteLoading = ref(false)
    const currentDelete = ref(null)
    const notify = ref(true)
    const snackbar = ref(false)
    const snackbarText = ref('')
    const listAdherents = ref([])
    const totalAdherents = ref([])
    const currentConseiller = ref({
      id: 0,
      nom: '',
      responsable: '',
    })

    const headers = ref([
      { text: 'CABINET', sortable: false, value: 'cabinet' },
      { text: 'RESPONSABLE', sortable: false, value: 'responsable' },
      { text: 'TÉLÉPHONE', sortable: false, value: 'telephone' },
      { text: 'E-MAIL', sortable: false, value: 'email' },
      { text: 'NB. BONS', sortable: false, value: 'nb_bons' },
      { text: 'UTILISÉS', sortable: false, value: 'nb_utilises' },
      { text: 'ACTIONS', value: 'actions', align: 'center' },
    ])

    const pagination = ref({
      itemsPerPage: 25,
      current: 1,
      total: 0,
      pageCount: 0,
    })

    const getConseillers = async () => {
      loading.value = true

      ConseillersData.value = []
      await axios.get(`conseillers?page=${pagination.value.page}&rowsPerPage=${pagination.value.itemsPerPage}`).then(response => {
        ConseillersData.value = response.data
        loading.value = false
      })
        .catch(e => {
          loading.value = false
          throw e
        })
    }

    const showConseillerDialog = (item = null) => {
      errorMessages.value = null
      errorField.value = {}
      if (form.value) {
        form.value.resetValidation()
      }
      if (item === null) {
        titleConseiller.value = "Ajout d'un nouveau cabinet"
        conseillerFormData.value = {
          id: null,
          cabinet: '',
          responsable: '',
          telephone: '',
          email: '',
          nom_referent: '',
          telephone_referent: '',
          email_referent: '',
          bon_id: null,
          code: '',
          nb_bons: '',
        }
      } else {
        titleConseiller.value = `Mettre à jour ${item.cabinet}`
        conseillerFormData.value = { ...conseillerFormData.value, ...item }
      }
      isDialogOpen.value = true
    }

    const showListDialog = async item => {
      isListOpen.value = true
      listAdherents.value = []
      currentConseiller.value.id = item.id
      currentConseiller.value.nom = item.cabinet
      currentConseiller.value.responsable = item.responsable
      loadingList.value = true
      tableAdherentsHeight.value = window.innerHeight - 420

      await axios.get(`adhesions/cgp/${item.id}`).then(response => {
        let statutAdherent = '?'
        let fullName = ''
        totalAdherents.value = response.data.length
        if (totalAdherents.value > 0) {
          response.data.forEach(adhesion => {
            if (adhesion.statut === 'societe') {
              const societe = adhesion.societe !== null ? adhesion.societe : '?'
              fullName = societe.toLowerCase()
              statutAdherent = 'Personne morale'
            } else {
              const nom = adhesion.nom !== null ? adhesion.nom : '?'
              const prenom = adhesion.prenom !== null ? adhesion.prenom : '?'
              fullName = `${nom} ${prenom}`.toLowerCase()
              statutAdherent = 'Personne physique'
            }
            listAdherents.value.push({
              fullName, statutAdherent, prejudice: currencyFormat(adhesion.sidepockets_sum_total_parts),
            })
          })
        }
      })
        .catch(e => {
          loadingList.value = false
          throw e
        })
        .finally(() => {
          loadingList.value = false
        })
    }

    const sendList = async id => {
      loadingSendList.value = true
      await axios.get(`conseillers/send-list/${id}`).then(() => {
        loadingSendList.value = false
        isListOpen.value = false
        snackbarText.value = `Liste envoyée à ${currentConseiller.value.responsable}`
        snackbar.value = true
      }).catch(() => {
        alert('Une erreur est survenue !')
        loadingSendList.value = false
      })
    }

    const printList = () => {
      const a = window.open('', '', 'height=650, width=650')
      a.document.write('<!DOCTYPE html><html>')
      a.document.write('<head><meta charset="utf-8" /><link rel="stylesheet" href="/print.css"><style>body{font-family: Arial, Helvetica, sans-serif;}h1{font-size: 18pt;}table{border-collapse: collapse;}td,th{font-size: 12pt}td{border: 1px solid #000000;padding: 0.2em 0.5em;}</style></head>')
      a.document.write(`<body> <h1>Adhérents ajoutés par ${currentConseiller.value.nom}</h1>`)
      a.document.write('<table width="100%"><thead><tr><th class="left" align="left">NOM</th><th class="center" align="center">STATUT</th><th class="right" align="right">PRÉJUDICE</th></tr></thead><tbody>')
      a.document.write(listAdherents.value.map(adherent => `<tr><td class="left border" align="left">${adherent.fullName}</td><td class="center border" align="center">${adherent.statutAdherent}</td><td class="right border" align="right">${adherent.prejudice}</td></tr>`).join(''))
      a.document.write('</tbody></table>')
      a.document.write('</body></html>')
      a.document.close()
      a.focus()
      a.print()
      a.close()
    }

    const onSubmit = async () => {
      errorMessages.value = null
      errorField.value = {}
      let apiPath = 'conseillers/add-with-bon'
      snackbarText.value = 'Conseiller ajouté !'
      if (!form.value.validate()) {
        return false
      }
      loadingBtModal.value = true

      if ((!conseillerFormData.value.code || !conseillerFormData.value.nb_bons) && notify.value === true) {
        errorMessages.value = 'Les notifications par email ne sont possible que lorque vous ajoutez un bon d\'achat'
        loadingBtModal.value = false

        return false
      }

      if (conseillerFormData.value.nb_bons < conseillerFormData.value.nb_utilises) {
        errorMessages.value = 'Attention, vous avez saisi un nombre de bons d\'achat inférieur à celui déjà utilisé par le cabinet.'
        loadingBtModal.value = false

        return false
      }

      if (conseillerFormData.value.id !== null) {
        apiPath = 'conseillers/update-with-bon'
        snackbarText.value = 'Conseiller modifié !'
      }

      const data = {
        conseiller: {
          id: conseillerFormData.value.id,
          cabinet: conseillerFormData.value.cabinet,
          responsable: conseillerFormData.value.responsable,
          telephone: conseillerFormData.value.telephone,
          email: conseillerFormData.value.email,
          nom_referent: conseillerFormData.value.nom_referent,
          telephone_referent: conseillerFormData.value.telephone_referent,
          email_referent: conseillerFormData.value.email_referent,
        },
        bon: {
          id: conseillerFormData.value.bon_id,
          code: conseillerFormData.value.code,
          nb_bons: conseillerFormData.value.nb_bons,
          nb_utilises: conseillerFormData.value.utilises,
        },
        notify: notify.value,
      }

      await axios
        .post(apiPath, data)
        .then(() => {
          loadingBtModal.value = false

          getConseillers()
          isDialogOpen.value = false
          snackbar.value = true
        })
        .catch(error => {
          loadingBtModal.value = false
          errorMessages.value = `Une erreur serveur est survenue : ${error.message}`
          Object.assign(errorField.value, error.response.data.errors)
        })

      form.value.resetValidation()

      return true
    }

    const showDelete = userId => {
      currentDelete.value = userId
      showDeleteDialog.value = true
    }

    const deleteConseiller = async () => {
      await axios.get(`conseillers/delete/${currentDelete.value}`).then(() => {
        currentDelete.value = null
        getConseillers()
        showDeleteDialog.value = false
      }).catch(e => {
        // eslint-disable-next-line no-alert
        alert('Une erreur est survenue !')
        console.error(e)
      })
    }

    const randomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min

    const randomElem = arr => arr[randomInt(0, arr.length - 1)]

    const codeGenerate = () => {
      const pattern = '#####-#####'
      const charsets = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      conseillerFormData.value.code = pattern.split('').map(char => {
        if (char === '#') {
          return randomElem(charsets)
        }

        return char
      }).join('')
    }

    watch(
      () => pagination.value,
      () => {
        getConseillers()
      },
    )

    return {
      ConseillersData,
      expanded,
      pagination,
      loading,
      loadingBtModal,
      loadingSendList,
      loadingList,
      deleteLoading,
      form,
      headers,
      mdiDeleteOutline,
      mdiPencil,
      mdiPlus,
      mdiAccountDetailsOutline,
      mdiPrinterOutline,
      mdiSendOutline,
      showDeleteDialog,
      isDialogOpen,
      isListOpen,
      titleConseiller,
      currentConseiller,
      totalAdherents,
      conseillerFormData,
      errorMessages,
      errorField,
      notify,
      snackbar,
      snackbarText,
      listAdherents,
      showDelete,
      deleteConseiller,
      getConseillers,
      showConseillerDialog,
      showListDialog,
      sendList,
      codeGenerate,
      onSubmit,
      tableAdherentsHeight,
      printList,
      validators: {
        required,
        emailValidator,
      },
      icons: {
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style scoped>
.v-list-item__title {
  text-transform: capitalize;
}
</style>
